<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="primary--border student_list" outlined>
          <v-card-title class="title" v-if="!reuseComponent"> Student Behaviour Report 

            <v-spacer></v-spacer>
             <!-- <print-buttons
              v-if="form.items.data.length > 0"
              :passAuthToReport="true"
              labelPdf="Download Pdf"
              labelExcel="Download Excel"
              :downloadUrl="downloadUrl"
              :pdf="false"
              :excel="true"
            ></print-buttons> -->

            <v-download-column-select
              v-if="
                ($auth.isAdmin() ||
                  $auth.loggedInRole().slug === 'administrator') &&
                form.items.data.length > 0
              "
              :showPDF="false"
              :downloadColumns="dynamicColumns"
              @onDownload="downloadRecord"
            >
              DOWNLOAD STUDENT BEHAVIOUR RECORD
            </v-download-column-select>
          </v-card-title>

          <v-scroll-y-transition v-if="!reuseComponent">
            <v-card outlined>
              <v-card-title class="pb-0 mb-0">
                <v-flex xs4>
                    <v-student-search-field
                        @onSelectStudent="selectStudent"
                     ></v-student-search-field>
                </v-flex>
                <v-flex xs3>
                    <!-- <v-grade-field
                        v-model="gradeId"
                        :multiple="true"
                        @selectedGrade="onGradeSelect"
                        >
                    </v-grade-field> -->
                    <v-select :disabled="enrollCode ? true :false" :items="grades" v-model="gradeId" label="Select Grades" multiple class="pt-0" outlined dense @change="onGradeSelect()">
                    <v-list-item slot="prepend-item" ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon :color="gradeId.length > 0 ? 'indigo darken-4' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item>
                    <v-divider slot="prepend-item" class="mt-2"></v-divider>
                    <v-divider slot="append-item" class="mb-2"></v-divider>
                  </v-select>
                </v-flex>
                <v-flex xs3>
                    <v-subject-field
                          v-model="subjectId"
                          :gradeId="gradeId"
                          :subjects="subjects"
                        ></v-subject-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs1>
                <search-button :permission="true" @action="get()">
                </search-button>
              </v-flex>
              <v-flex xs1>
                <v-btn
                  @click="moreFilterDialogue = true"
                  color="primary"
                  style="margin-top: -20px; float: right"
                  elevation="2"
                  icon
                  ><v-icon>filter_alt</v-icon></v-btn
                >
              </v-flex>
              <!-- <v-flex>
                <transition
                name="animate-css-transition"
                enter-active-class="animated fadeInRight"
                leave-active-class="animated fadeOutRight"
              >
                <v-btn
                  id="staff_clear_filter"
                  outlined
                  color="primary"
                  text
                  class="mt-n7-2 staff_clear_filter"
                >
                  Clear
                </v-btn>
              </transition>
              </v-flex> -->
            
              </v-card-title>
            </v-card>
          </v-scroll-y-transition>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template  v-slot:item="{ index, item }">
               <tr>
                    <td align="left">{{ index + form.items.meta.from }}</td>
                    <td class="text-xs-left">
                        {{ item.title }} <v-chip small label outlined :color="item.group_type==='positive' ? 'success' :'error'" >{{item.group_type}}</v-chip>
                    </td>
                    <td class="text-xs-left" v-if="!reuseComponent">
                        {{ item.student_name }}    
                        <div v-if="item.enroll_code">
                            <span style="font-size: 10px; color: #666"
                            >(Enroll Code: {{ item.enroll_code }})</span
                            >
                            <!-- <br>
                            <span style="font-size: 10px; color: #666"
                            >(Grade: {{ item.grade_name }})</span
                            > -->
                        </div>          
                    </td>
                    <td class="text-xs-left" v-if="!reuseComponent">
                      {{ item.grade_name }}
                      <div v-if="item.section_name">
                        <span style="font-size: 10px; color: #666">
                          (Section: {{item.section_name}})
                        </span>
                      </div>
                    </td>
                    <td v-if="item.rating">
                      <span v-for="n in item.rating" :key="n" class="material-icons" :style="item.group_type === 'negative' ? 'color:indianred': 'color:green'">
                          grade
                      </span>
                    </td>
                    <td v-else>
                      -
                    </td>
                    <td v-if="item.rating !=0 ">
                        {{ item.percentage  }}%
                    </td>
                    <td v-else>
                        0%
                    </td>
                    <td>
                        {{ item.subject_name }}
                    </td>
                   
                    <td>
                        {{ item.submitted_date }} {{ item.submitted_time }}
                    </td>
                    <td>
                        {{ item.submitted_by }}
                    </td>

               </tr>
            </template>
          </v-data-table>
          <v-dialog v-model="moreFilterDialogue" persistent max-width="550">
            <v-card>
              <v-card-title class="title pa-3 primary white--text">
                <v-icon class="mr-2 white--text">filter_alt</v-icon>
                More Filters
              </v-card-title>
              <v-card-title class="title pa-3">
                <br>
                <v-flex xs12>
                  <v-select
                      :items="groups"
                      required
                      class="pa-0 mb-0"
                      label="Group Type"
                      outlined
                      dense
                      v-model="group_status"
                    >

                  </v-select>
                </v-flex>
                <br/>
                <v-flex xs12 mt-5>
                  <v-select
                      :items="ratingFilters"
                      required
                      class="pa-0 mb-0"
                      label="Rating %"
                      outlined
                      dense
                      v-model="rating_percentage"
                    >

                  </v-select>
                </v-flex>
                <br>
                <v-flex xs12 mt-5>
                  <v-user-search-field
                  @onSelectUser="searchUser"
                ></v-user-search-field>
                </v-flex>
                <v-flex mt-5 xs12>
                  <v-calendar-field
                    id-val="from_date"
                    v-model="frmDate"
                    label="From Date"
                  >
                  </v-calendar-field>
                </v-flex>
                <br>
                <v-flex mt-5 xs12>
                    <v-calendar-field
                    id-val="to_date"
                    v-model="toDate"
                    label="To Date"
                  >
                  </v-calendar-field>
                </v-flex>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  outlined
                  small
                  @click="moreFilterDialogue = false"
                  >Close</v-btn
                >
                <v-btn color="success" @click="get()" small>
                  Filter Now
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Form from "@/library/Form";

import Mixins from "@/library/Mixins";
import { redirectDownloadUrl } from "../../../../library/helpers";
const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
export default {
  props:{
    reuseComponent:{
      default:false
    },
    group_type_status:{
      default:'all',
    },
    enroll_code_comp:{
      default:'',
    }
  },
  mixins: [Mixins],
  data: () => ({
    form: new Form({}, '/api/student-behaviour-report'),
    enrollId:'',
    gradeId:'',
    subjects:[],
    subjectId:'',
    enrollCode:'',
    submitDate:'',
    downloadUrl:'',
    frmDate:'',
    toDate:'',
    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      { text: "Behaviour Title ", align: "left", value: "behaviour_title", sortable: false },
      {
        text: "Student Name",
        align: "left",
        value: "student_name",
        sortable: false,
      },
      {
        text: "Grade(Section)",
        align: "left",
        value: "grade_section",
        sortable: false,
      },
      {
        text: "Ratings",
        align: "left",
        value: "star",
        sortable: false,
      },
      {
        text: "%",
        align: "left",
        value: "%",
        sortable: false,
      },
      {
        text: "Subject",
        align: "left",
        value: "subject",
        sortable: false,
      },
      {
        text: "Date & time",
        align: "left",
        value: "date_time",
        sortable: false,
      },
      {
        text: "Submitted By",
        align: "left",
        value: "submitted_by",
        sortable: false,
      },
    //   { text: "Action", align: "right", value:'action', sortable: false },
    ],

    downloadColumns: [
      {
        value: "behaviour_title",
        name: "Behaviour Title",
        selected: false,
      },
      {
        value: "student_name",
        name: "Student Name",
        selected: false,
      },
      {
        value: "grade_section",
        name: "Grade / Section",
        selected: false,
      },
      {
        value: "rating",
        name: "Rating",
        selected: false,
      },
      {
        value: "perc",
        name: "%",
        selected: false,
      },
      {
        value: "subject",
        name: "Subject",
        selected: false,
      },
      {
        value: "submit_date",
        name: "Date & Time",
        selected: false,
      },
      {
        value: "submit_by",
        name: "Submitted By",
        selected: false,
      },
     
    ],
    groups:[
      {value:'all', text:"All"},
      {value:'positive', text:"Positive"},
      {value:'negative', text:"Negative"},
    ],
   
    group_status:'',
    moreFilterDialogue:false,
    grades:[],
    ratingFilters:[
      {value:'1', text: "20%"},
      {value:'2', text: "40%"},
      {value:'3', text: "60%"},
      {value:'4', text: "80%"},
      {value:'5', text: "100%"},
    ],
    rating_percentage:'',
    submitted_by:'',
  }), 
  watch:{
    pagination: function () {
      if(this.gradeId || this.subjectId || this.enrollId || this.enrollCode || this.group_status || this.frmDate || this.toDate ) {
        this.get();
      }
    },
  },
  mounted(){
    if(this.reuseComponent){
      this.group_status = this.group_type_status
      this.enrollCode = this.enroll_code_comp
      this.headers = this.headers.filter((item)=>{
        if(item.value !== 'student_name' && item.value !== 'grade_section' ){
          return item
        }
      })
    }
    this.getGrades();

  },

  computed: {
    dynamicColumns() {
      let columns = [];
      this.downloadColumns.map((item) => {
        if (item.toAdmin === true && this.$auth.isAdmin()) {
          columns.push(item);
        } else if (!item.toAdmin) {
          columns.push(item);
        }
      });
      return columns;
    },
    selectAllGrades() {
      return this.gradeId.length === this.grades.length;
    },
    selectFewGrades() {
      return this.gradeId.length > 0 && !this.selectAllGrades;
    },
    icon() {
      if (this.selectAllGrades) return "check_box";
      if (this.selectFewGrades) return "indeterminate_check_box";
      return "add_box";
    },
  },
 

  methods: {
    get(params) {
        let extraParams = "&enroll_id="+this.enrollId+"&grade_id="+this.gradeId+"&subject_id="
                            +this.subjectId+"&enroll_code="+this.enrollCode+"&submit_date="+this.submitDate+"&from_date="+this.frmDate+"&to_date="+this.toDate+"&group_type="+this.group_status+"&reusecomponent="+this.reuseComponent+"&rating="+this.rating_percentage+"&submitted_by="+this.submitted_by;
        let query = [null, undefined].includes(params) ? this.queryString(extraParams) : params;
        this.form.get(null, query)
    },

    selectStudent(student) {
      if(student){
        this.enrollId = student.enroll_id;
        this.enrollCode = student.enroll_code;
      }
    },

    onGradeSelect() {
      if(this.gradeId.length ==1){
        this.getSubjects()
      }else{
        this.subjects = [];
      }
    },
    getSubjects() {
        this.$rest.get("/api/subject?grade=" + this.gradeId).then(({ data }) => {
          this.subjects = data.data.map((item) => {
              return { id: item.id, text: item.name };
          });
        });
    },
    downloadRecord({ type, columns, columnString }) {
        redirectDownloadUrl({
            uri: "/download/academic/student-behaviour-report",
            queryString: `columns=${columnString}&enroll_id=${this.enrollId}&grade_id=${this.gradeId}&subject_id=${this.subjectId}&enroll_code=${this.enrollCode}&submit_date=${this.submitDate}&from_date=${this.frmDate}&to_date=${this.toDate}&group_type=${this.group_status}&rating=${this.rating_percentage}&submitted_by=${this.submitted_by}`,
      });
    },
    getGrades() {
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&roleWise=true&slim=true"
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllGrades) {
          this.gradeId = [];
        } else {
          this.gradeId = this.grades.map((grade) => {
            return grade.value;
          });
        }
      });
    },

    searchUser(user) {
      this.submitted_by = user.id;
    },

  },
};
</script>
